import {
  capitalize,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Switch,
} from "@mui/material";
import { t } from "i18next";
import React from "react";
import { useSelector } from "react-redux";
import {
  IContentChannel,
  IOrganizationUnit,
} from "../../../../../../types/NendaTypes";
import { stringToColor } from "../../../../../utils/string";
import { selectContentChannels } from "../../../store/reducers/contentChannelReducer";

interface ContentChannelsProps {
  premise: IOrganizationUnit;
  contentChannels: string[];
  setContentChannels: (value: string[]) => void;
}

const ContentChannels: React.FC<ContentChannelsProps> = ({
  premise,
  contentChannels,
  setContentChannels,
}) => {
  const currentChannels = contentChannels;
  const channels = useSelector(
    (state: any) => selectContentChannels(state) || []
  ) as IContentChannel[];

  const filteredChannels = channels
    .filter((c) => c.isPublic && c.playlist.length > 0)
    .map((x) => x._id);

  const toggleEnabled = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    let updatedEnabled = [...(currentChannels || [])];
    const channel = event.target.name;
    if (checked) {
      updatedEnabled.push(channel);
    } else {
      updatedEnabled = updatedEnabled.filter((x) => x != channel);
    }
    setContentChannels(updatedEnabled);
  };

  const toggleShowIsPublic = () => {
    let updatedEnabled = [...(currentChannels || [])];

    if (
      currentChannels?.some((channel) => filteredChannels.includes(channel))
    ) {
      updatedEnabled = updatedEnabled.filter(
        (channel) => !filteredChannels.includes(channel)
      );
    } else {
      updatedEnabled = [...new Set([...updatedEnabled, ...filteredChannels])];
    }

    setContentChannels(updatedEnabled);
  };

  const deToggleAll = () => {
    setContentChannels([]);
  };

  const allPublicChannels = channels
    .filter((c) => c.isPublic)
    .map((c) => c._id);

  const areAllPublicChannelsSelected = allPublicChannels?.every((channel) =>
    currentChannels?.includes(channel)
  );

  return (
    <Paper sx={{ p: 1 }}>
      <List>
        {allPublicChannels?.length > 0 && (
          <ListItem disablePadding sx={{ py: 2 }}>
            <ListItemText primary={"All public channels"} />
            <ListItemSecondaryAction>
              <Switch
                name={"all"}
                checked={areAllPublicChannelsSelected}
                onChange={
                  areAllPublicChannelsSelected
                    ? deToggleAll
                    : toggleShowIsPublic
                }
              />
            </ListItemSecondaryAction>
          </ListItem>
        )}
        <Divider />
        {channels?.map((item) => (
          <React.Fragment key={item._id}>
            <ListItem disablePadding>
              <ListItemText
                primary={item.name}
                secondary={`${capitalize(t("common.assets"))}: ${
                  item.playlist.length
                }`}
              />
              <ListItemSecondaryAction>
                <Chip
                  label={item.category}
                  sx={{
                    backgroundColor: stringToColor(item.category) + "55",
                  }}
                />
                <Switch
                  name={item._id}
                  checked={currentChannels?.includes(item._id)}
                  onChange={toggleEnabled}
                />
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
      </List>
    </Paper>
  );
};

export default ContentChannels;
