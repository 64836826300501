// Import statements
import { getCompanyIdByPremise } from "@client/utils/company";
import {
  AddOutlined,
  ArrowDownwardOutlined,
  ArrowUpwardOutlined,
  CloseOutlined,
  DragHandle,
  RemoveOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  AssetType,
  IAsset,
  ISignagePlaylist,
  ISignagePlaylistItem,
  LoadingStatus,
  NavigationLevel,
} from "@nendaTypes/NendaTypes";
import { t } from "i18next";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import DigitalSignageAddAssetModal from "../components/DigitalSignagePlaylist/DigitalSignageAddAssetModal";
import { CustomerPortalState, store } from "../store";
import {
  GetCompanyAssets,
  GetPremiseAssets,
  selectAssetsByHierarchy,
} from "../store/reducers/assetReducer";
import { selectNavigatedPremise } from "../store/reducers/organizationUnitReducer";
import {
  selectCurrentNavigationLevel,
  selectNavigatedCompanyId,
  selectNavigationScope,
} from "../store/reducers/workspaceReducer";
import BackButton from "../ui-components/buttons/BackButton";
import SortableList from "../ui-components/dnd/SortableList";
import TextInput, { InputError } from "../ui-components/input/TextInput";
import TextInputWithLabel from "../ui-components/input/TextInputWithLabel";
import { clipString } from "@client/utils/string";
import {
  createCompanySignagePlaylist,
  createPremiseSignagePlaylist,
  getCompanySignagePlaylists,
  getPremiseSignagePlaylists,
  selectSignagePlaylistById,
  selectSignagePlaylistLoadingStatus,
  updateCompanySignagePlaylist,
  updatePremiseSignagePlaylist,
} from "../store/reducers/signagePlaylistReducer";
import HelpWidget from "../ui-components/display/HelpWidget";
import ClickableImagePreview from "../ui-components/media/ClickableImagePreview";
import CenteredLoadingIndicator from "../ui-components/loading/CenteredLoadingIndicator";
type SignagePlaylistWithItemsAndId = ISignagePlaylist & {
  items: SignagePlaylistItem[];
};

export const SIGNAGE_UPLOAD_REQUEST_ID = "signage";
type SignagePlaylistItem = ISignagePlaylistItem & { id: string };

const initialState: SignagePlaylistWithItemsAndId = {
  name: "",
  defaultItemDuration: 10,
  items: [],
  company: "",
  premise: "",
  isSoundEnabled: false,
};

const DigitalSignagePlaylist = ({ create = false }: { create?: boolean }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const scope = useSelector(selectNavigationScope);
  const navigate = useNavigate();
  const { playlistId } = useParams<{ playlistId: string }>();
  const [playlistState, setPlaylistState] =
    useState<SignagePlaylistWithItemsAndId>(initialState);
  const navigationLevel = useSelector(selectCurrentNavigationLevel);
  const navigatedPremise = useSelector(selectNavigatedPremise);
  const navigatedCompanyId = useSelector(selectNavigatedCompanyId);
  const premise = useSelector(selectNavigatedPremise);
  const assets = useSelector((state: CustomerPortalState) =>
    selectAssetsByHierarchy(state, navigationLevel)
  );
  const playlist = useSelector((state: CustomerPortalState) =>
    selectSignagePlaylistById(state, playlistId || "")
  );
  const playlistLoadingStatus = useSelector(selectSignagePlaylistLoadingStatus);
  const playlistsLoading = playlistLoadingStatus === LoadingStatus.LOADING;
  const playlistErrors = useMemo(() => {
    const newErrors: InputError[] = [];
    if (!playlistState.name) {
      newErrors.push({
        field: "name",
        message: t(
          "customerportal.pages.digital_signage.validation.playlist_name_required"
        ),
      });
    }
    if (playlistState.items.length === 0) {
      newErrors.push({
        field: "items",
        message: t(
          "customerportal.pages.digital_signage.validation.playlist_items_required"
        ),
      });
    }
    if (playlistState.items.some((i) => Number(i.durationInSeconds) <= 0)) {
      newErrors.push({
        field: "items",
        message: t(
          "customerportal.pages.digital_signage.validation.duration_required"
        ),
      });
    }
    return newErrors;
  }, [playlistState.name, playlistState.items]);

  const isAvailableForPremise =
    (playlist?.company === getCompanyIdByPremise(premise) &&
      !playlist?.premise) ||
    (playlist?.premise === premise?._id &&
      navigationLevel === NavigationLevel.PREMISE);

  const isAvailableCompanyPlaylist =
    !playlist?.premise && navigationLevel === NavigationLevel.COMPANY;

  const notFound =
    !playlist || (!isAvailableForPremise && !isAvailableCompanyPlaylist);

  const handleAddAsset = (assetId: string, duration?: number) => {
    const fixedDuration = Number(duration?.toFixed());
    setPlaylistState((prevState) => {
      const newItems = [...prevState.items];
      newItems.push({
        id: prevState.defaultItemDuration + assetId + Math.random().toString(), // Some ID to identify the item for drag and drop sorting
        asset: assetId,
        durationInSeconds: fixedDuration || prevState.defaultItemDuration,
      });
      return { ...prevState, items: newItems };
    });
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPlaylistState((prevPlaylistState) => ({
      ...prevPlaylistState,
      name: e.target.value,
    }));
  };

  const handleSetItems = (items: SignagePlaylistItem[]) => {
    setPlaylistState((prevState) => ({ ...prevState, items }));
  };

  const handleChangeSoundEnabled = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPlaylistState({ ...playlistState, isSoundEnabled: e.target.checked });
  };
  const handleDurationChange = (index: number, duration: number) => {
    setPlaylistState((prevState) => {
      const newItems = [...prevState.items];
      newItems[index].durationInSeconds = duration;
      return { ...prevState, items: newItems };
    });
  };

  const playlistIsCompanyLevel =
    playlist?.company === navigatedCompanyId && !playlist?.premise;
  const playlistIsPremiseLevel = playlist?.premise === navigatedPremise?._id;
  const [errors, setErrors] = useState<InputError[]>([]);
  const [shouldValidate, setShouldValidate] = useState(false);

  useEffect(() => {
    if (playlistErrors) {
      setErrors(playlistErrors);
    }
  }, [playlistErrors]);

  const savePlaylist = () => {
    setShouldValidate(true);
    if (!playlistState || playlistErrors.length > 0) {
      return;
    }
    const playlistItems = playlistState.items.map((item) => ({
      asset: item.asset,
      durationInSeconds: item.durationInSeconds,
    }));
    const defaultPayload = {
      ...playlistState,
      items: playlistItems,
      company: navigatedCompanyId,
    };
    if (create) {
      if (navigationLevel === NavigationLevel.COMPANY) {
        store.dispatch(
          createCompanySignagePlaylist({
            ...defaultPayload,
            premise: undefined,
          })
        );
      } else if (navigationLevel === NavigationLevel.PREMISE) {
        const createPayload = {
          ...playlistState,
          premise: navigatedPremise?._id,
        };
        store.dispatch(createPremiseSignagePlaylist(createPayload));
      }
    } else {
      if (playlistIsCompanyLevel) {
        store.dispatch(updateCompanySignagePlaylist(defaultPayload));
      } else if (playlistIsPremiseLevel) {
        store.dispatch(updatePremiseSignagePlaylist(defaultPayload));
      }
    }
    navigate(-1);
  };

  const isPremiseLevel = navigationLevel === NavigationLevel.PREMISE;
  // Fetch the playlist based on the playlistId
  useEffect(() => {
    if (create) return;
    if (playlist) {
      const items: SignagePlaylistItem[] = playlist.items.map((item) => {
        const id =
          item.durationInSeconds + item.asset + Math.random().toString();
        const durationInSeconds =
          item.durationInSeconds || playlist.defaultItemDuration;
        return { ...item, id, durationInSeconds };
      });
      setPlaylistState({ ...playlist, items });
    }
  }, [playlist, create]);

  useEffect(() => {
    if (navigatedPremise && isPremiseLevel) {
      store.dispatch(GetPremiseAssets(navigatedPremise._id));
      store.dispatch(GetCompanyAssets(getCompanyIdByPremise(premise)));
    } else if (navigatedCompanyId && !isPremiseLevel) {
      store.dispatch(GetCompanyAssets(navigatedCompanyId));
    }
  }, [
    navigatedPremise,
    navigatedCompanyId,
    isPremiseLevel,
    navigatedPremise?._id,
    premise,
  ]);

  useEffect(() => {
    if (scope.company && !playlist) {
      store.dispatch(getCompanySignagePlaylists(scope.company));
    }
    if (scope.premise && !playlist) {
      store.dispatch(getPremiseSignagePlaylists(scope.premise));
    }
  }, [scope.company, scope.premise, playlist]);

  if (playlistsLoading) {
    return <CenteredLoadingIndicator />;
  }

  if (
    playlistLoadingStatus === LoadingStatus.SUCCEEDED &&
    !create &&
    (!playlistState || notFound)
  ) {
    return (
      <Box>
        <Typography variant="body1">
          {t("customerportal.pages.digital_signage.playlist_not_found")}
        </Typography>
      </Box>
    );
  }

  if (playlistLoadingStatus === LoadingStatus.IDLE && !create && !playlist) {
    return null;
  }
  const isCompanyPlaylist = !playlistState.premise && !!playlistState.company;
  const saveDisabled = isCompanyPlaylist && isPremiseLevel;
  return (
    <Box>
      <DigitalSigangePlaylistHeader create={create} />
      <DigitalSigangePlaylistContent
        isMobile={isMobile}
        playlistState={playlistState}
        handleNameChange={handleNameChange}
        handleSoundEnabledChange={handleChangeSoundEnabled}
        handleSetItems={handleSetItems}
        handleDurationChange={handleDurationChange}
        assets={assets}
        onAddAsset={handleAddAsset}
        onSavePlaylist={savePlaylist}
        errors={shouldValidate ? errors : undefined}
        saveDisabled={saveDisabled}
      />
    </Box>
  );
};

const DigitalSigangePlaylistHeader = ({ create }: { create: boolean }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box
      sx={{
        display: "flex",
        gap: "1rem",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "1rem",
          alignItems: "center",
        }}
      >
        <BackButton />
        <Typography variant="h3">
          {create
            ? t("customerportal.pages.digital_signage.create_playlist")
            : t("customerportal.pages.digital_signage.edit_playlist")}
        </Typography>
        {!isMobile && (
          <HelpWidget
            title={t("common.help")}
            details={
              <Box>
                <Typography>
                  {t("customerportal.pages.digital_signage.playlist_help")}
                </Typography>
              </Box>
            }
          />
        )}
      </Box>
    </Box>
  );
};

interface DigitalSignagePageContentProps {
  isMobile: boolean;
  playlistState: SignagePlaylistWithItemsAndId;
  handleNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSetItems: (items: SignagePlaylistItem[]) => void;
  handleDurationChange: (index: number, duration: number) => void;
  assets: IAsset[];
  onAddAsset: (assetId: string) => void;
  onSavePlaylist: () => void;
  handleSoundEnabledChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  errors?: InputError[];
  saveDisabled?: boolean;
}

const DigitalSigangePlaylistContent: React.FC<
  DigitalSignagePageContentProps
> = ({
  isMobile,
  playlistState,
  handleNameChange,
  handleSoundEnabledChange,
  handleSetItems,
  handleDurationChange,
  assets,
  onAddAsset,
  onSavePlaylist,
  errors,
  saveDisabled,
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        minHeight: "30rem",
        gap: "0.5rem",
        width: "100%",
        my: "1rem",
      }}
    >
      <Box
        sx={{
          width: isMobile ? "100%" : "30%",
          padding: "1rem",
          background: theme.palette.background.paper,
          borderRadius: "0.3rem",
        }}
      >
        <SignagePlaylistForm
          onNameChange={handleNameChange}
          onSoundEnabledChange={handleSoundEnabledChange}
          playlistState={playlistState}
          errors={errors}
          disableEdit={saveDisabled}
        />
      </Box>
      <Box
        sx={{
          flex: 1,
          borderRadius: "0.3rem",
          padding: "1rem",
          background: theme.palette.background.paper,
        }}
      >
        <Box>
          <Box sx={{ borderBottom: "0.5px solid lightgray" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexDirection: isMobile ? "column" : "row",
                width: "100%",
              }}
            >
              <Typography variant="h6">{playlistState.name}</Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "1rem",
                  width: !isMobile ? undefined : "100%",
                  py: "1rem",
                }}
              >
                {errors &&
                  errors.map((error) => (
                    <Typography
                      variant="body1"
                      fontSize={"0.7rem"}
                      key={error.field}
                      color="error"
                    >
                      {error.message}
                    </Typography>
                  ))}
                {!isMobile && (
                  <Button
                    disabled={saveDisabled}
                    variant="contained"
                    onClick={onSavePlaylist}
                    sx={{ width: isMobile ? "100%" : undefined }}
                  >
                    {t("common.save")}
                  </Button>
                )}
              </Box>
            </Box>
            <Box sx={{ position: "relative" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  pl: "3rem",
                  pr: isMobile ? "2.7rem" : "3.5rem",
                  pb: "0.5rem",
                  width: "100%",
                  borderBottom: "0.5px solid lightgray",
                }}
              >
                <Typography variant="body2" fontSize={"0.7rem"}>
                  {t("customerportal.pages.assets.asset")}
                </Typography>
                <Typography variant="body2" fontSize={"0.7rem"}>
                  {t("common.file.duration_in_seconds")}
                </Typography>
              </Box>
              <SortableSignagePlaylistItems
                items={playlistState.items}
                onSortChange={handleSetItems}
                onDurationChange={handleDurationChange}
                assets={assets}
                disabled={saveDisabled}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                p: "2rem",
              }}
            >
              <DigitalSignageAddAssetModal
                onClose={onAddAsset}
                assets={assets}
                disabled={saveDisabled}
              />
            </Box>
          </Box>
          <Box>
            {isMobile && (
              <Button
                disabled={saveDisabled}
                variant="contained"
                onClick={onSavePlaylist}
                sx={{
                  width: isMobile ? "100%" : undefined,
                  mt: "2rem",
                  p: isMobile ? "0.8rem 1rem" : undefined,
                }}
              >
                {t("common.save")}
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const SortableSignagePlaylistItems = ({
  items,
  onSortChange,
  onDurationChange,
  assets,
  disabled,
}: {
  items: SignagePlaylistItem[];
  onSortChange: (items: SignagePlaylistItem[]) => void;
  onDurationChange: (index: number, duration: number) => void;
  assets: IAsset[];
  disabled?: boolean;
}) => {
  const onRemoveItem = (id: string) => {
    onSortChange(items.filter((item) => item.id !== id));
  };
  const renderItem = (
    item: SignagePlaylistItem,
    index: number,
    listeners: any
  ) => {
    const moveItemUp = () => {
      if (index === 0) return; // Can't move the first item up
      const newItems = [...items];
      [newItems[index - 1], newItems[index]] = [
        newItems[index],
        newItems[index - 1],
      ];
      onSortChange(newItems);
    };

    const moveItemDown = () => {
      if (index === items.length - 1) return; // Can't move the last item down
      const newItems = [...items];
      [newItems[index], newItems[index + 1]] = [
        newItems[index + 1],
        newItems[index],
      ];
      onSortChange(newItems);
    };
    return (
      <SortableSignagePlaylistItem
        key={item.asset}
        dragHandleListeners={listeners}
        item={item}
        index={index}
        onDurationChange={onDurationChange}
        assets={assets}
        onRemoveItem={onRemoveItem}
        onMoveItemUp={moveItemUp}
        onMoveItemDown={moveItemDown}
        sortingIsDisabled={items.length <= 1}
        disabled={disabled}
      />
    );
  };

  const handleOnDrop = (dropResult: SignagePlaylistItem[]) => {
    onSortChange(dropResult);
  };

  return (
    <SortableList
      items={items}
      renderItem={renderItem}
      onChange={handleOnDrop}
      disabled={disabled}
    />
  );
};

const SortableSignagePlaylistItem = ({
  item,
  index,
  onDurationChange,
  dragHandleListeners,
  assets,
  onRemoveItem,
  onMoveItemUp,
  onMoveItemDown,
  sortingIsDisabled,
  disabled,
}: {
  item: SignagePlaylistItem;
  index: number;
  onDurationChange: (index: number, duration: number) => void;
  dragHandleListeners: any;
  assets: IAsset[];
  onRemoveItem: (id: string) => void;
  onMoveItemUp: () => void;
  onMoveItemDown: () => void;
  sortingIsDisabled?: boolean;
  disabled?: boolean;
}) => {
  const theme = useTheme();

  const durationAdd = () => {
    if (item.durationInSeconds !== undefined) {
      onDurationChange(index, item.durationInSeconds + 1);
    }
  };
  const durationReduce = () => {
    if (item.durationInSeconds !== undefined && item.durationInSeconds > 0) {
      onDurationChange(index, item.durationInSeconds - 1);
    }
  };
  const handleSetDuration = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    const numberValue = Number(value);
    if (!isNaN(numberValue)) {
      onDurationChange(index, numberValue);
    } else {
      console.warn("Invalid input: not a number");
    }
  };

  const handleRemoveItem = () => {
    onRemoveItem(item.id);
  };

  const asset = assets.find((a) => a._id === item.asset);
  const isDurationSelectDisabled = asset?.type === AssetType.VIDEO;
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  if (!asset) {
    return null;
  }
  const SortingHandle = () => {
    return isMobile ? (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "0.5rem",
        }}
      >
        {sortingIsDisabled ? null : (
          <>
            <IconButton onClick={onMoveItemUp}>
              <ArrowUpwardOutlined sx={{ width: "1rem" }} />
            </IconButton>
            <IconButton onClick={onMoveItemDown}>
              <ArrowDownwardOutlined sx={{ width: "1rem" }} />
            </IconButton>
          </>
        )}
      </Box>
    ) : (
      <DragHandle {...dragHandleListeners} />
    );
  };
  return (
    <Box
      sx={{
        p: isMobile ? undefined : "0.5rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: "0.5px solid lightgray",
        position: "relative",
      }}
    >
      {disabled && (
        <Box
          sx={{
            position: "absolute",
            inset: 0,
            background: "rgba(255,255,255,0.5)",
            zIndex: 99,
            cursor: "not-allowed",
          }}
        />
      )}
      <Box sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>
        <SortingHandle />
        <ClickableImagePreview
          url={asset.locator}
          width="5rem"
          type={asset.type}
          thumbnail={asset.thumbnailLocator}
        />
        {!isMobile && (
          <Typography variant="body1">{clipString(asset.name, 30)}</Typography>
        )}
      </Box>
      {!isMobile && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            flex: 1,
            px: "1rem",
          }}
        >
          <Chip
            size="small"
            label={asset.type}
            sx={{
              background: theme.palette.transparent.blue,
              color: "white",
            }}
          />
        </Box>
      )}
      <Box sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
        <Box
          sx={{
            background: theme.palette.transparent.blue,
            visibility: isDurationSelectDisabled ? "hidden" : "visible",
            opacity: 0.7,
            "&:hover": { opacity: 1 },
            transition: "opacity 0.2s",
            borderRadius: "0.2rem",
          }}
          onClick={isDurationSelectDisabled ? () => {} : durationReduce}
        >
          <RemoveOutlined />
        </Box>
        <TextInput
          disabled={isDurationSelectDisabled}
          error={Number(item.durationInSeconds) <= 0}
          value={item.durationInSeconds}
          onChange={handleSetDuration}
          variant="standard"
          sx={{ ml: "auto", width: "4rem", textAlign: "center" }}
        />
        <Box
          sx={{
            background: theme.palette.transparent.blue,
            transition: "opacity 0.2s",
            visibility: isDurationSelectDisabled ? "hidden" : "visible",
            opacity: 0.7,
            "&:hover": { opacity: 1 },
            borderRadius: "0.2rem",
          }}
          onClick={isDurationSelectDisabled ? () => {} : durationAdd}
        >
          <AddOutlined />
        </Box>
        <Box>
          <CloseOutlined onClick={handleRemoveItem} />
        </Box>
      </Box>
    </Box>
  );
};

interface SignagePlaylistFormProps {
  onNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  playlistState: ISignagePlaylist;
  onSoundEnabledChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  errors?: InputError[];
  disableEdit?: boolean;
}
const SignagePlaylistForm: React.FC<SignagePlaylistFormProps> = ({
  onNameChange,
  playlistState,
  onSoundEnabledChange,
  errors,
  disableEdit,
}) => {
  const nameError = errors?.find((error) => error.field === "name");
  return (
    <Box sx={{ mb: "1rem" }}>
      <TextInputWithLabel
        disabled={disableEdit}
        placeholder={t(
          "customerportal.pages.digital_signage.playlist_name_placeholder"
        )}
        variant="standard"
        label={t("customerportal.pages.digital_signage.playlist_name")}
        value={playlistState.name}
        onChange={onNameChange}
        error={!!nameError}
        sx={{
          marginTop: "0.3rem",
          width: "100%",
        }}
      />
      <FormControlLabel
        control={
          <Checkbox
            disabled={disableEdit}
            checked={playlistState.isSoundEnabled}
            onChange={onSoundEnabledChange}
          />
        }
        labelPlacement="end"
        label={t("customerportal.pages.digital_signage.sound_enabled")}
      />
    </Box>
  );
};

export default DigitalSignagePlaylist;
