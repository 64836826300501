import { Document, ObjectId, Schema } from "mongoose";
import {
  DayOfWeek,
  Hour,
  Minute,
  NestedKeys,
  DateRange,
  Sorted,
} from "./HelperTypes";
import {
  ApiAsset,
  ApiAssetType,
  ApiEndUserAccount,
  ApiProductOfferings,
} from "./RedBeeTypes";
import { IAsset as RBMAsset } from "../shared/types/RedBee.types";
import { CountryCode, TimezoneName } from "countries-and-timezones";
import { UpsalesProductId } from "../shared/types/Upsales.types";
import { SentMessageInfo } from "nodemailer";

export interface IPricing {
  price: number;
  currency: string;
  showPrice: boolean;
}

export type Status =
  | LoadingStatus.IDLE
  | LoadingStatus.LOADING
  | LoadingStatus.SUCCEEDED
  | LoadingStatus.FAILED;

export enum LoadingStatus {
  IDLE = "idle",
  LOADING = "loading",
  SUCCEEDED = "succeeded",
  FAILED = "failed",
}

export interface IRoomLabel {
  label: string;
  value: string;
}

export enum DeviceOS {
  WEB_OS = "webOS",
  TIZEN = "Tizen",
  ANDROID_TV = "AndroidTV",
}

export enum NavigationLevel {
  COMPANY = "company",
  PREMISE = "premise",
  ADMIN = "admin",
}

export enum NendaProduct {
  STREAMING_APP = "STREAMING_APP",
  FRONTLAYER = "FRONTLAYER",
  SIGNAGE = "SIGNAGE",
  SIGNAL_DELIVERY = "SIGNAL_DELIVERY",
}

// -- FRONTLAYER --

export interface IDeviceApplication {
  platformAppIds: Partial<Record<DeviceOS, string>>;
}

export enum FrontLayerItemType {
  APPLICATION = "application",
  ROUTE = "route",
  IMAGE = "image",
}

export enum FrontLayerInformationSource {
  URL = "url",
  IMAGE = "image",
  HTML = "html",
}
interface AbstractFrontLayerInformationItem {
  name: string;
}

export interface FrontLayerInformationURLItem
  extends AbstractFrontLayerInformationItem {
  sourceType: FrontLayerInformationSource.URL;
  content: IFrontLayerInformationURLContent;
}

export interface FrontLayerInformationImageItem
  extends AbstractFrontLayerInformationItem {
  sourceType: FrontLayerInformationSource.IMAGE;
  content: IFrontLayerInformationImageContent;
}

export interface FrontLayerInformationHTMLItem
  extends AbstractFrontLayerInformationItem {
  sourceType: FrontLayerInformationSource.HTML;
  content: IFrontLayerInformationHTMLContent;
}

export type FrontLayerInformationItem =
  | FrontLayerInformationURLItem
  | FrontLayerInformationImageItem
  | FrontLayerInformationHTMLItem;

export interface IFrontLayerInformationURLContent {
  locator: string;
}

export interface IFrontLayerInformationImageContent {
  images: IFrontLayerInformationImage[];
}

export type IFrontLayerInformationImage = Sorted<string, "locator">;

export interface IFrontLayerInformationHTMLContent {
  html: string;
}

export enum FrontLayerBackgroundType {
  COLOR = "color",
  IMAGE = "image",
}

export interface IRadioPage extends IFrontLayerSubpage<IRadioStation> {
  selectedLocalP4Station: string;
}

export type IApplicationsPage = IFrontLayerSubpage<IFrontLayerApplication>;

export type IFrontLayerInformationPage =
  IFrontLayerSubpage<FrontLayerInformationItem>;

export interface IFrontLayerApplication extends IFrontLayerItem {
  application: IDeviceApplication;
}

type Localisation = Record<string, string>;

export interface IFrontLayerItem {
  id: string;
  name?: Localisation;
  iconName?: string;
  backgroundColor?: string;
  selectedBackgroundColor?: string;
  font?: string;
  fontColor?: string;
  selectedFontColor?: string;
  borderColor?: string;
  selectedBorderColor?: string;
  imageUrl?: string;
  backgroundImageUrl?: string;
  backgroundTypes: FrontLayerBackgroundType[];
  iconType: "image" | "icon";
  enabled: boolean;
  type: FrontLayerItemType;
  description?: Localisation;
}

export interface IFrontLayerClockConfiguration {
  font: string;
  color: string;
}

export interface IFrontLayerSubpage<
  T extends IFrontLayerItem | IRadioStation | FrontLayerInformationItem
> extends IFrontLayerItemDesignConfiguration {
  items: Array<T>;
  backgroundTypes: FrontLayerBackgroundType[];
  backgroundColor?: string;
  backgroundImageUrl?: string;
}

export enum FrontLayerSectionType {
  HEADER = "header",
  FOOTER = "footer",
}

export interface IFrontLayerVerticalSection {
  backgroundColor?: string;
}

export interface IFrontLayerItemDesignConfiguration {
  fontColor?: string;
  selectedFontColor?: string;
  backgroundImageUrl?: string;
  defaultItemColor?: string;
  defaultSelectedItemColor?: string;
  defaultItemBorderColor?: string;
  defaultSelectedItemBorderColor?: string;
  isBorderSelectionEnabled?: boolean;
}

export interface IFrontlayerConfiguration
  extends IBaseModel,
    IFrontlayerConfigValues {
  name: string;
  company: Document["_id"];
  premise?: Document["_id"];
}

export interface IFrontlayerConfigValues {
  logoUrl?: string;
  backgroundTypes: FrontLayerBackgroundType[];
  backgroundColor?: string;
  backgroundImageUrl?: string;
  font: string;
  fontColor: string;
  imageItemUrl?: string;
  defaultItemColor: string;
  defaultSelectedItemColor: string;
  defaultItemBorderColor: string;
  defaultSelectedItemBorderColor: string;
  defaultSelectedFontColor: string;
  isBorderSelectionEnabled: boolean;
  information: IFrontLayerInformationPage;
  radio: IRadioPage;
  applications: IApplicationsPage;
  clock: IFrontLayerClockConfiguration;
  homeScreenItems: Array<IFrontLayerItem | IFrontLayerApplication>;
  backButton: IFrontLayerItemDesignConfiguration;
  header: IFrontLayerVerticalSection;
  footer: IFrontLayerVerticalSection;
}

export const SignageSlotAmountValues = [0, 10, 20] as const;
export const ChannelOverrideDurationValues = [
  5, 15, 30, 45, 60, 90, 120, 240, 480,
] as const;
export type SignageSlotAmount = (typeof SignageSlotAmountValues)[number];

export enum ScheduleContentRefType {
  CONTENT_CHANNEL = "ContentChannel",
  SIGNAGE_PLAYLIST = "SignagePlaylist",
}
export const scheduledContentRefTypes = [
  ScheduleContentRefType.CONTENT_CHANNEL,
  ScheduleContentRefType.SIGNAGE_PLAYLIST,
] as const;

export type ScheduledContentRefType = (typeof scheduledContentRefTypes)[number];

export interface IContentOverride {
  content: Document["_id"];
  contentRefType: ScheduledContentRefType;
  from: Date;
  to: Date;
}

export interface IWeeklyScheduleItem {
  screenId: string;
  schedule: { [key: string]: string | null };
}

export interface ISignageConfig {
  numberOfSceens?: number;
  isSignageAsContentEnabled: boolean;
  arePromotionsEnabled: boolean;
  adPermissions: IAdPermission;
  numberOfHourlySignageSlots?: SignageSlotAmount;
  isSoundEnabled?: boolean;
  contentOverride?: IContentOverride;
  // default content values are currently not nested to avoid migration
  defaultContent?: Document["_id"];
  defaultContentRefType: ScheduledContentRefType;
}

export interface IRadioStation {
  id: string;
  externalId?: string;
  name: string;
  url: string;
  disabled?: boolean;
}

export interface IFrontLayerOptions {
  availableDeviceApps: IFrontLayerApplication[];
  availableHomeScreenItems: (IFrontLayerItem | IFrontLayerApplication)[];
}

// -- ORGANIZATION UNIT --
export interface IRegex {
  areaRegex: [string, string];
  roomRegex: [string, string];
}

export enum OrganizationUnitType {
  GLOBAL = "global",
  HOTEL_CHAIN = "hotelChain",
  HOTEL = "hotel",
  ROOM = "room",
}

export interface IChannelMap {
  enabled: boolean;
  channels: Record<string, string>;
}

export interface IAddress {
  streetAddress: string;
  postalCode: string;
  city: string;
  country: CountryCode;
  timezone: TimezoneName;
  region: string;
}

export interface IContactPerson {
  fullName: string;
  phoneNumber: string;
  emailAddress: string;
}

export interface IContract {
  startDate: Date;
  endDate?: Date;
}

export interface IBusinessHours {
  hours: [string, string];
  isOpen: boolean;
}

export type WeeklyBusinessHours = Partial<Record<DayOfWeek, IBusinessHours>>;

export interface IOrganizationUnit extends IBaseModel {
  numberOfRooms: number;
  name: string;
  address: IAddress;
  contactPerson: IContactPerson;
  contract?: IContract;
  company?: string | Company;
  category?: string;
  numberOfEntries?: number; // entries per week
  dwellTimeInMinutes?: number;
  type: OrganizationUnitType;
  parent: string;
  roomTypes: Array<IRoomType>;
  redBeeName?: string;
  defaultRoomType?: Document["_id"];
  logoUrl?: string;
  hash?: string;
  upsalesCompanyId?: number;
  upsalesSubscriptionId?: number;
  streamingContentManagerEnabled: boolean;
  pmsHotelId?: string;
  pmsToken?: string;
  apiKey?: string;
  regex: IRegex;
  reseller?: string;
  systemIntegrator?: string;
  allowContinuousStreaming?: boolean;
  showNonPurchasedContent?: boolean;
  frontlayerConfig?: string | null;
  frontlayerOptions?: IFrontLayerOptions; // The available options when making a frontlayer configuration for an OU
  channelMap: IChannelMap;
  nendaProducts: Array<NendaProduct>;
  contentChannels?: string[];
  signageConfig?: Pick<
    ISignageConfig,
    | "numberOfHourlySignageSlots"
    | "isSignageAsContentEnabled"
    | "arePromotionsEnabled"
  >;
  assets?: Partial<Record<NendaProduct, IAsset[]>>;
  businessHours?: WeeklyBusinessHours;
  tags: Pick<ITag, "_id" | "name">[];
  adminTags: Pick<ITag, "_id" | "name">[];
  adnuntiusSiteId?: string;
  contentManagement?: ContentProductMappingData;

  findRoomType: (roomTypeId: string) => IRoomType | null;
}

export interface ProductMapping {
  name: string;
  isBaseProduct: boolean;
  roomTypeCategory: RoomTypeCategory;
  upsalesProduct: number;
  RBMOfferings: string[];
}

export type PricedProductMapping = ProductMapping & { price: number };

export interface ContentPackage extends ProductMapping {
  id: string;
  price: number;
  assets: RBMAsset[]; // TODO: this can be slimmed down
}

export interface ContentManagmentData {
  premiseId: string;
  availablePackages: Partial<Record<RoomTypeCategory, ContentPackage[]>>;
  roomContent: RoomContent[];
}

export interface RoomContent {
  roomId: string;
  roomTypeId: string;
  roomTypeCategory?: RoomTypeCategory;
  currentContent: string[];
  state: RoomContentState;
}

export enum RoomContentState {
  VALID = "valid",
  INVALID = "invalid",
}

export interface ContentProductMappingData {
  platformFeeProductIds: number[];
  productMapping: ProductMapping[];
}

// -- ROOM --
export enum PMSStateStatus {
  FREE = "Free",
  OCCUPIED = "Occupied",
}
export interface IPMSRoomState {
  messageId: string;
  roomNumber: string;
  premiseId: string;
  status: PMSStateStatus;
  guestStatus?: Array<GuestStateMapping>;
  stayDetails?: {
    arrivalDate: Date | string;
    departureDate: Date | string;
    guestFirstName?: string;
    guestLastName?: string;
    bookingId: string;
    guestSalutation?: string; // Mr., Ms, etc.
    guestLanguage?: string; // IETF language tag, e.g. "en", "en-US", "en-GB", "pl", "pl-PL"
  };
}

export interface GuestStateMapping {
  id: string;
  state: GuestState;
}

export enum GuestState {
  CHECKED_IN = 1,
  CHECKED_OUT = 2,
}

export enum RoomStatus {
  ACTIVE = "active",
  INACTIVE = "inactive",
}

export interface AutoplayAsset {
  enabled: boolean;
  id: string;
  type?: ApiAssetType;
}

export interface IAutocompleteAsset {
  text: any;
  type: string;
  assetId: string;
}

export interface IRoom extends IBaseModel {
  name: string;
  description: string;
  carousels: Array<Document["_id"]>;
  pages: Array<Document["_id"]>;
  redBeeAccountId: string;
  roomNumber: number;
  roomIdentifier: Schema.Types.Number | number;
  hotel: Document["_id"];
  hotelChain: Document["_id"];
  roomType: Document["_id"];
  lastPMSStatus?: IPMSRoomState;
  account?: ApiEndUserAccount;
  labels?: Array<IRoomLabel>;
  status: RoomStatus;
  deviceId?: string;
  autoplayChannelId?: number;
  autoplayAsset?: AutoplayAsset;
  nendaProducts: Array<NendaProduct>;
  signage: {
    config?: ISignageConfig;
    schedule: {
      promotions: Array<Document["_id"]>;
      content: {
        weekly: WeeklyScheduledContent;
      };
    };
  };
  streamChannels: Array<Document["_id"]>;
  tags: Pick<ITag, "_id" | "name">[];
  adminTags: Pick<ITag, "_id" | "name">[];
  numberOfDevices: number;
  devices: string[];
  adnuntiusAdUnitId?: string;
  adnuntiusAuId?: string;
  adnuntiusTagId?: string;
  addLogToHistory: (status: HistoryTypes) => Promise<void>;
}

export type WeeklyScheduledContent = {
  [key in DayOfWeek]?: string | ObjectId;
};

export enum DeviceType {
  TV = "tv",
  STB = "stb",
  TABLET = "tablet",
  PHONE = "phone",
  COMPUTER = "computer",
}

export interface IDevice extends IBaseModel {
  type?: DeviceType;
  manufacturer?: string;
  deviceModel?: string;
  enabled: boolean;
  operatingSystem?: {
    name: string;
    version: string;
  };
  additionalData?: any; // note sure if worth to have a field where additional data can just be dumped
  firstSeen?: Date;
  lastSeen?: Date;
  lastIP?: string;
}

// -- ROOM TYPE --
export enum RoomTypeCategory {
  ROOM = "room",
  COMMON_AREA = "common_area",
  PUBLIC = "public",
}

export interface IRoomType extends IBaseModel {
  numberOfRooms: number;
  hotelChain: Document["_id"];
  name: string;
  carousels: Array<Document["_id"]>;
  pages: Array<Document["_id"]>;
  category: RoomTypeCategory;
  numberOfReferences?: number; // Used on response
}

// -- CAROUSEL --
export enum CarouselTypes {
  RECOMMEDED = "recommended",
  CURATED = "curated",
  QUERY = "query",
  CONTINUE_WATCHING = "continue_watching",
  EPG = "epg",
}

export enum LogicConnectors {
  AND = "AND",
  OR = "OR",
}

export type CarouselView = "CAROUSEL" | "SEE_ALL";

export type CarouselAssetType = Exclude<
  ApiAssetType,
  "AD" | "COLLECTION" | "PODCAST" | "PODCAST_EPISODE" | "OTHER"
>;

export interface ICarousel extends IBaseModel {
  type: CarouselTypes; // default "curated"
  assetIds: Array<string>;
  tagIds: Array<string>;
  name: string;
  title?: Array<ITitle>;
  channelId?: string;
  assetTypeFilter?: CarouselAssetType[];
  liveEventChannelFilter?: Array<string>;
  sort?: string;
  query?: string;
  logicConnector?: LogicConnectors;
  groupByTitle?: boolean;
  filterDuplicates?: boolean;
  showNonPurchasedContent?: boolean;
  displayLimit?: number;
}

export type CarouselAvailabilityStatus =
  | "AVAILABLE"
  | "UNAVAILABLE"
  | "PARTIAL"
  | "UNKNOWN";

export interface ICarouselWithAsset extends ICarousel {
  assets: Array<ApiAsset>;
  availabilityStatus?: CarouselAvailabilityStatus;
  totalCount: number;
}

export interface CleanedCarousel {
  id: string;
  type: CarouselTypes;
  name: string;
  title: { text: any; language: any }[];
  assets: ApiAsset[];
  channelId?: string;
  daysForward?: number;
  daysBackward?: number;
  displayLimit?: number;
  query?: string;
  sort?: string;
  totalCount?: number;
  availabilityStatus?: CarouselAvailabilityStatus;
}

// -- SUBPAGE --
export interface ISubpage extends IBaseModel {
  name: string;
  carousels: Array<Document["_id"]>;
  title: Array<ITitle>;
  draft: boolean;
  icon?: string;
}

// -- USER --
export interface IUser extends IBaseModel {
  email: string;
  type: Role;
  password: string;
  company?: Document["_id"];
  premises?: Array<Document["_id"]>;
}

export interface UserSession {
  expires: number;
  username: string;
  role: Role;
  company: string;
  premises?: string[];
  issuedAt: number;
}

export enum Role {
  // Internal Nenda roles
  Admin = "admin",
  Superuser = "basic",
  // External customer roles
  SystemIntegrator = "systemIntegrator",
  PremiseAdmin = "premiseAdmin",
  PremiseUser = "premiseUser",
  PremiseGroupUser = "premiseGroupUser",
  Reseller = "reseller",
  ResellerAdmin = "resellerAdmin",
  CompanyAdmin = "companyAdmin",
  CompanyUser = "companyUser",
}

export type SimpleUser = Pick<
  IUser,
  "email" | "type" | "company" | "_id" | "premises" | "createdAt" | "updatedAt"
>;

// -- SETTINGS --
export interface ISettings {
  languages?: Array<ISettingsLanguage>;
  defaultContentBrandingLogo?: ImageOverlay;
  roomCategories?: Array<{ name: string; value: string }>;
  roomTypeCategories?: { [key: string]: RoomTypeCategory };
}

export interface ISettingsLanguage {
  name: string;
  value: string;
}

// -- MISC --
export interface ITitle {
  language: string;
  text: string;
}

export enum SortDirection {
  DESCENDING = "desc",
  ASCENDING = "asc",
}

export interface IBaseModel {
  createdAt?: Date | string;
  updatedAt?: Date | string;
  _id?: Document["_id"];
  __v?: any;
}

// -- PRODUCT OFFERINGS --
export enum ProductOfferingType {
  PROVISION = "provision",
  TVOD = "tvod",
  SVOD = "svod",
}

export interface IProductOfferings extends ApiProductOfferings {
  numberOfAssets?: number;
  type?: string;
}

// -- HISTORY --
export enum HistoryTypes {
  ACTIVATION = "activation",
  DEACTIVATION = "deactivation",
  UPDATE = "update",
  CREATION = "creation",
}

export interface ITransaction {
  timestamp: string;
  type: string | HistoryTypes;
  ipAddress: string;
}

export enum ParentType {
  HOTEL_CHAIN = "hotelChain",
  HOTEL = "hotel",
  ROOM = "room",
}

export interface IHistory extends Document, IBaseModel {
  parentType: ParentType;
  transactions: Array<ITransaction>;
  parent: string;
  addTransaction: (
    type: HistoryTypes,
    ipAddress: string,
    date?: Date
  ) => Promise<void>;
  getActiveTimeBetweenDates: (from: Date, to?: Date) => Promise<number>;
}

// -- REPORTS --
export interface IReport {
  rooms?: Array<IRoomsReport>;
  subscriptions?: Array<ISubscriptionsReport>;
  packages?: Array<IPackagesReport>;
  content?: Array<IContentReport>;
  transactions?: Array<ITransactionReport>;
}
export interface IRoomsReport {
  hotelChainId: string;
  hotelId: string;
  chain: string;
  premise: string;
  roomCategory: string;
  activeRoomCount: number;
  roomCount: number;
}
export interface ISubscriptionsReport {
  hotelChainId: string;
  hotelId: string;
  chain: string;
  premise: string;
  activeRoomCount: number;
  packageName: string;
}

export interface IPackagesReport {
  hotelChainId: string;
  chain: string;
  packageName: string;
  assetNames: string;
}

export interface IContentReport {
  provider: string;
  content: string;
  hotelChainId: string;
  chain: string;
  rooms: number;
  common_area: number;
  publicArea: number;
}

export interface ITransactionReport {
  hotelChainId: string;
  hotelId: string;
  roomId: string;
  chain: string;
  premise: string;
  room: string;
  date: string;
  type: string;
  asset: string;
  price: number | string;
}

// -- TEMPLATES --
export interface ITemplate {
  content: string;
  name: string;
  subject?: string;
}

// -- ORDERS --

export type PremiseOrderRequest = Omit<
  IPremiseOrder,
  | "orderNumber"
  | "creator"
  | "creatorCompany"
  | "dateCreated"
  | "dateSigned"
  | "dateCanceled"
  | "dateCompleted"
>;

export interface IPremiseOrder {
  orderNumber: string;
  orderStatus?: PremiseOrderStatus;
  premise: IPremise;
  order: IPremiseOrderLine[];
  pricing: IPremiseOrderPricing;
  invoice: IInvoice;
  creator?: string;
  creatorCompany?: Company;
  dateCreated?: string;
  dateSigned?: string;
  dateCanceled?: string;
  dateCompleted?: string;
}

export interface IPremise {
  name: string;
  company: string;
  organizationNumber: string;
  street: string;
  city: string;
  postalCode: string;
  contactName: string;
  contactEmail: string;
  signerName: string;
  signerEmail: string;
  primaryMarket?: Market;
}

export interface IPremiseOrderLine {
  roomType: RoomTypeCategory;
  numberOfRooms: number;
  content: UpsalesProduct[];
}

export interface IPremiseOrderPricing {
  pricingRows: IPricingRow[];
  //commission: number;
}

export interface IPricingRow {
  productId: number;
  productName: string;
  units: number;
  price: number;
  listPrice: number;
  commission: number;
  currency: string;
  type: string;
}

export interface IInvoice {
  street: string;
  city: string;
  postalCode: string;
  email: string;
  contractLength?: number;
  startDate?: Date;
  invoiceMarking?: string;
}

export enum EmailTemplateNames {
  INVOICE = "invoice",
  INVOICES = "invoices",
  DELETE_DATA = "deleteData",
  PREMISEORDER = "premiseOrder",
  PREMISEORDER_SIGNED = "premiseOrderSigned",
  USER_CREATED = "userCreated",
  USER_RESET_PASSWORD = "userResetPassword",
  ELGIGANTEN_ORDER_CONFIRMATION = "ElgigantenOrderConfirmation",
  ELGIGANTEN_ORDER_SIGNED = "ElgigantenOrderSigned",
  CONTENT_ORDER_USER_CONFIRMATION = "contentOrderConfirmation",
  CONTENT_ORDER = "contentOrder",
}

export enum Market {
  HOTEL = "hotel",
  CARE = "care",
  GYM = "gym",
  OFFICE = "office",
  RESTAURANT = "restaurant",
}

export enum PremiseOrderStatus {
  ORDERED = "ordered", // order has been recieved
  SIGNED = "signed", // order has been signed by the customer
  COMPLETED = "completed", //order has been part of a monthly report that has been sent to parter (e.g Elgiganten)
  CANCELED = "canceled", // order has been canceled (e.g. customer never signed)
}

// product ID, commission rate in percent
export type ProductCommissions = Record<number, number>;
// product ID, max discount in percent
export type ProductDiscount = Record<number, number>;

export type UpsalesProduct = any; //TODO: need the correct type
export interface UpsalesProducts {
  content: {
    ca: Array<UpsalesProduct>;
    public: Array<UpsalesProduct>;
    room: Array<UpsalesProduct>;
  };
  generic: Array<UpsalesProduct>;
}

// -- Content order --
export interface ContentOrder {
  orderRows: ContentOrderRow[];
}

export interface ContentOrderRow {
  roomId: string;
  content: UpsalesProductId[];
}

export interface OrderMailData {
  premiseId: string;
  premiseName: string;
  ordererMail: string;
  orderRows: DetailedOrderRow[];
  totalPrice: string;
  upsalesCompanyId: number;
}

export interface OrderRowContent {
  name: string;
  price: string;
  upsalesProductId: number;
  offerings: string[];
  roomTypeCategory: RoomTypeCategory;
}

export interface DetailedOrderRow {
  roomId: string;
  roomName: string;
  currentContent: OrderRowContent[];
  content: OrderRowContent[];
  groupedContent: {
    packageNames: string;
    detailedPackageNames: string;
    combinedPackagePrice: string;
  };
}

export interface ContentOrderResponse {
  wasOrderMailSent: boolean;
  wasUserConfirmationMailSent: boolean;
  orderSentMessageInfo: SentMessageInfo;
  userSentMessageInfo: SentMessageInfo;
  error: any;
  orderDetails: OrderMailData;
}

// --- COMPANY ---

export interface Company extends IBaseModel {
  name: string;
  parent?: Company | string;
  type: CompanyType;
  organizationNumber?: string;
  externalId?: string;
  upsalesId?: string;
  signageConfig?: ISignageConfig;
}

export enum CompanyType {
  RESELLER = "reseller",
  SYSTEM_INTEGRATOR = "systemintegrator",
  CUSTOMER = "customer",
  ADVERTISER = "advertiser",
}

// --- SIGNAGE ---

export enum OverlayPosition {
  TOP_LEFT = "top-left",
  TOP_RIGHT = "top-right",
  BOTTOM_LEFT = "bottom-left",
  BOTTOM_RIGHT = "bottom-right",
}

export interface ImageOverlay {
  locator: string;
  thumbnailLocator?: string;
  position: OverlayPosition;
}

export enum AssetSource {
  NENDA = "Nenda",
  REDBEE = "RedBee",
  EXTERNAL = "External",
}

export enum AssetType {
  IMAGE = "image",
  VIDEO = "video",
  LINEAR_CHANNEL = "linearchannel",
  URL = "url",
  HTML = "html",
}

export enum BookingPriority {
  HIGH = 1,
  MEDIUM = 2,
  LOW = 3,
}

export enum ScheduleSlotType {
  SIGNAGE = "Signage",
  ADVERT = "Ad",
  CONTENT = "Content",
}

export enum ContentScheduleType {
  SCHEDULED = "scheduled",
  OVERRIDE = "override",
  DEFAULT = "default",
}

export type ScheduleSlots = Partial<Record<Minute, ILeanAsset[]>>;

export interface IAdPlaylist extends IBaseModel {
  assets: ILeanAsset[];
}

export interface IAsset extends IBaseModel {
  name: string;
  premise?: Document["_id"];
  company: Document["_id"];
  product: NendaProduct;
  locator: string;
  thumbnailLocator?: string;
  type: AssetType;
  source: AssetSource;
  fileSize?: number;
  mimeType?: string;
}

export type ILeanAsset = Pick<
  IAsset,
  "name" | "locator" | "type" | "source" | "_id" | "mimeType" | "fileSize"
>;

export type FileUploadScope = FileUploadType.LOGO | FileUploadType.POSTER;
export enum FileUploadType {
  LOGO = "logo",
  POSTER = "poster",
}

export interface LogoOverlay extends ImageOverlay {
  useInPlayer: boolean;
}

export interface WebTokenSessionData {
  role: Role | undefined;
  expires: number | undefined;
  username: string | undefined;
  company: string | undefined;
  premises: string[] | undefined;
  issuedAt: number | undefined;
}

export interface IAdPermission {
  external: ITakeoverPermission;
  signage: ITakeoverPermission;
}

export interface ITakeoverPermission {
  fullTakeoverAllowed: boolean;
  bannerAllowed: boolean;
}

export interface IContentChannel extends IBaseModel {
  name: string;
  description: string;
  category: string;
  playlist: ILeanAsset[];
  hasSound: boolean;
  isPublic: boolean;
  isAutoShuffleEnabled: boolean;
  adPermissions: IAdPermission;
  sortOrder: number;
  logo?: LogoOverlay;
  posterUrl?: string;
  previewImageUrl?: string;
  previewVideoUrl?: string;
  branding?: {
    allowed: boolean;
    brandingPositionOverride?: OverlayPosition;
  };
}

export interface ISignagePlaylistItem {
  asset: Document["_id"];
  durationInSeconds?: number;
}

export interface ISignagePlaylist extends IBaseModel {
  name: string;
  company: Document["_id"];
  premise?: Document["_id"];
  items: ISignagePlaylistItem[];
  defaultItemDuration: number;
  isSoundEnabled: boolean;
}

export interface Time {
  hour: Hour;
  minute: Minute;
}

export interface IPromotionSchedule {
  date: DateRange;
  time: {
    start: Time;
    end: Time;
  };
  days: DayOfWeek[];
}

export interface ISignagePromotion extends IBaseModel {
  name: string;
  company: Document["_id"];
  premise?: Document["_id"];
  playlist: IPromotionPlaylist;
  schedule: IPromotionSchedule;
  isHighPriority: boolean;
}

export type IPromotionPlaylist = IPromotionPlaylistItem[];

export interface IPromotionPlaylistItem {
  asset: Document["_id"];
  durationInSeconds: number;
  sortOrder: number;
}

export type PopulatedPromotionPlaylistItem = Omit<
  IPromotionPlaylistItem,
  "asset"
> & {
  asset: IAsset;
};

export type SignagePromotionWithPopulatedPlaylist = Omit<
  ISignagePromotion,
  "playlist"
> & {
  playlist: PopulatedPromotionPlaylistItem[];
};

export interface IDailyContentPlaylist extends IBaseModel {
  name: string;
  premise: Document["_id"];
  timeline: ContentTimeline;
}

export type ContentTimeline = ITimeLineItem[];

export interface ITimeLineItem {
  content: Document["_id"];
  contentRefType: ScheduledContentRefType;
  interval: ITimeInterval;
}

export interface ITimeInterval {
  start: Time;
  end: Time;
}

export interface IAdCampaign extends IBaseModel {
  name: string;
  company: Document["_id"];
  startDate: Date;
  endDate: Date;
  asset: ILeanAsset;
  screens: Document["_id"][];
}

export type IAdCampaignWithCompany = Omit<IAdCampaign, "company"> & {
  company: Company;
};

export enum Scope {
  CanCreate = "can-create",
  CanEdit = "can-edit",
  CanOrder = "can-order",
  CanDelete = "can-delete",
  CanView = "can-view",
  CanAdministrate = "can-administrate",
}

export enum Resource {
  Premise = "premise",
  User = "user",
  Company = "company",
  Unit = "unit",
  Ads = "ads",
  ProductOfferings = "productOfferings",
  ContentChannel = "contentChannel",
  Booking = "booking",
  Asset = "asset",
  FrontlayerConfig = "frontlayerConfig",
  StreamChannel = "streamChannel",
  SignagePromotion = "signagePromotion",
  SignagePlaylist = "signagePlaylist",
  DailyContentPlaylist = "dailyContentPlaylist",
}

export type OrganizationType = Resource.Company | Resource.Premise;

export type Permission = Partial<Record<Role, Scope[]>>;
export type ResourcePermissions = Partial<Record<Resource, Permission>>;
export type Restriction = { resource: Resource; scopes: Scope[] };

export interface IDashboardCredential extends IBaseModel {
  premises: string[];
}

export interface IStreamKey extends IBaseModel {
  channel: IStreamChannel["_id"];
  keyValue: string;
  keyId: string;
  start: Date;
  end: Date;
}

export enum StreamType {
  HLS = "HLS",
}

export interface IStreamChannel extends IBaseModel {
  name: string;
  externalId: string;
  streamLinks: Partial<Record<StreamType, string>>;
}

export type PatchRequest<T> = {
  data: Partial<T>;
  unsetFields?: NestedKeys<T>[];
};

export enum CacheLevel {
  All = 0,
  PremiseGroup = 1,
  Premise = 2,
  Room = 3,
}

export enum TaggableObject {
  Premise = "premise",
  Screen = "screen",
}

export interface ITag extends IBaseModel {
  name: string;
  applicableTo: TaggableObject[];
  company?: Document["_id"];
  isAdminTag: boolean;
}

declare module "fastify" {
  export interface InternalApiRequest extends FastifyRequest {
    decodedSession: UserSession;
    params: any;
  }
}
