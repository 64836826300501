import { LoadingButton } from "@mui/lab";
import { Box, Button, Grid, Switch, Typography } from "@mui/material";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  FileUploadType,
  LogoOverlay,
  OverlayPosition,
} from "../../../../../types/NendaTypes";
import ConfirmationDialog from "../../ConfirmationDialog";
import { CustomerPortalState, store } from "../../store";
import {
  ClearUploadedFile,
  UploadFile,
  selectIsLoading,
} from "../../store/reducers/contentChannelReducer";
import { FileUpload } from "../FileUpload";
import OverlayPositionSelect from "./OverlayPositionSelect";

interface ChannelLogoProps {
  channelId: string;
  logo?: LogoOverlay;
  brandingPositionOverride?: OverlayPosition;
  onLogoChange: (logo?: LogoOverlay) => void;
  onBrandingPositionOverrideChange: (position?: OverlayPosition) => void;
}

export const VALID_FILE_TYPES = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "image/gif",
  "image/svg",
  "image/webp",
];

const ChannelLogo: React.FC<ChannelLogoProps> = ({
  channelId,
  logo,
  brandingPositionOverride,
  onLogoChange,
  onBrandingPositionOverrideChange,
}) => {
  const [file, setFile] = useState<File | undefined>(undefined);
  const [showUploadForm, setShowUploadForm] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isOverrideBranding, setIsOverrideBranding] = useState(false);
  const [brandingPosition, setBrandingPosition] = useState<OverlayPosition>(
    OverlayPosition.TOP_RIGHT
  );
  const uploadedLogo = useSelector(
    (state: CustomerPortalState) => state.contentChannel.uploads.logo
  );

  useEffect(() => {
    if (brandingPositionOverride) {
      setBrandingPosition(brandingPositionOverride);
      setIsOverrideBranding(brandingPositionOverride !== undefined);
    }
  }, [brandingPositionOverride]);

  const isLoading = useSelector(selectIsLoading);

  const uploadLogo = () => {
    if (!file) return;
    store.dispatch(UploadFile(channelId, file, FileUploadType.LOGO));
  };

  const handleDeleteButtonClick = () => {
    setIsDeleteDialogOpen(true);
  };

  const handleToggleUseLogoInPlayer = () => {
    if (!logo) return;
    onLogoChange({ ...logo, useInPlayer: !logo.useInPlayer });
  };

  const toggleBrandingPositionOverride = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsOverrideBranding(event.target.checked);
    if (event.target.checked) {
      onBrandingPositionOverrideChange(brandingPosition);
    } else {
      onBrandingPositionOverrideChange(undefined);
    }
  };

  const handleBrandingPositionChange = (position: OverlayPosition) => {
    setBrandingPosition(position);
    onBrandingPositionOverrideChange(position);
  };

  const handleDeleteDialogClose = (isConfirmed: boolean) => {
    setIsDeleteDialogOpen(false);
    if (isConfirmed) {
      onLogoChange(undefined);
    }
  };

  const toggleUploadForm = () => {
    setShowUploadForm(!showUploadForm);
  };

  useEffect(() => {
    if (uploadedLogo) {
      onLogoChange({
        ...logo,
        locator: uploadedLogo,
        useInPlayer: false,
        position: OverlayPosition.BOTTOM_LEFT,
      });
      store.dispatch(ClearUploadedFile(FileUploadType.LOGO));
    }
  }, [uploadedLogo, logo, onLogoChange]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Grid container spacing={2} columns={{ xs: 1, md: 2 }}>
        <Grid item xs={1}>
          <Typography sx={{ mb: "2rem" }} variant="h6">
            {t("customerportal.pages.content_channels.channel_logo")}
          </Typography>
          {logo && (
            <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
              <Box>
                <Typography variant="subtitle1">
                  {t("customerportal.pages.content_channels.use_in_player")}
                </Typography>
                <Switch
                  checked={logo?.useInPlayer}
                  onChange={handleToggleUseLogoInPlayer}
                />
              </Box>
              {logo?.useInPlayer && (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1rem",
                    }}
                  >
                    <Typography variant="subtitle1">
                      {t(
                        "customerportal.pages.content_channels.channel_logo_position"
                      )}
                    </Typography>
                    <OverlayPositionSelect
                      overlayPosition={logo.position}
                      onChange={(position) =>
                        onLogoChange({ ...logo, position })
                      }
                    />
                  </Box>
                  <Box sx={{ mt: "1rem" }}>
                    <Typography variant="subtitle1">
                      {t(
                        "customerportal.pages.content_channels.override_branding_position"
                      )}
                    </Typography>
                    <Switch
                      checked={isOverrideBranding}
                      onChange={toggleBrandingPositionOverride}
                    />
                    {isOverrideBranding && (
                      <OverlayPositionSelect
                        overlayPosition={brandingPosition}
                        onChange={handleBrandingPositionChange}
                      />
                    )}
                  </Box>
                </>
              )}
              <ConfirmationDialog
                title="Are you sure you want to delete the channel logo?"
                content="Note that the change is not applied until you save your changes."
                isOpen={isDeleteDialogOpen}
                onClose={handleDeleteDialogClose}
              />
            </Box>
          )}
        </Grid>
        <Grid item xs={1}>
          {logo && (
            <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
              <Box>
                <img src={logo.locator} width={"100%"} />
              </Box>
              <Button
                size="small"
                color="error"
                sx={{ height: "30px" }}
                variant="outlined"
                onClick={handleDeleteButtonClick}
              >
                {t("common.delete")}
              </Button>
            </Box>
          )}
          {!logo && (
            <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
              <Button variant="outlined" onClick={toggleUploadForm}>
                {showUploadForm
                  ? t("common.cancel")
                  : t(
                      "customerportal.pages.content_channels.upload_channel_logo"
                    )}
              </Button>
              {showUploadForm && (
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <FileUpload
                    files={file ? [file] : []}
                    onFileChange={(files: File[]) => setFile(files[0])}
                    allowedFileTypes={VALID_FILE_TYPES}
                  />
                  <LoadingButton
                    disabled={!file}
                    color="primary"
                    sx={{ mt: 1 }}
                    variant="outlined"
                    loading={isLoading}
                    onClick={uploadLogo}
                  >
                    {t("common.upload")}
                  </LoadingButton>
                </Box>
              )}
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ChannelLogo;
